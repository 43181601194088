<!-- 首页 -->
<template>
  <div class="EntryStoma">
    <div class="carousel">
      <el-carousel :interval="5000" arrow="always" height="500px">
        <el-carousel-item v-for="item in 4" :key="item" class="carouselitem">
          <img src="../../assets/bannan1.jpg" alt />
        </el-carousel-item>
      </el-carousel>
      <div class="hr-10"></div>
    </div>

    <div class="centebox">
      <el-row :gutter="20">
        <el-col :span="15" class="homescnew">
          <el-card class="box-card tzhggao">
            <div class="header">
              <router-link to="/declare" class="tzhggaomoes">更多></router-link>
            </div>
            <div class="slide">
              <ul>
                <li
                  v-for="(item, key) in Noticelsit"
                  :key="key"
                  v-show="key < 6"
                >
                  <span class="createTime">{{
                    item.createTime | formatDate
                  }}</span>
                  <a @click="requestNotice(item.id)" style="cursor: pointer">{{
                    item.title
                  }}</a>
                </li>
              </ul>
            </div>
          </el-card>
          <div class="hr-10"></div>
          <el-card class="box-card cardboder">
            <div slot="header" class="clearfix header">
              <span>最新政策</span>
              <router-link to="/list/?id=14951035198115840" class="moes"
                >更多></router-link
              >
            </div>
            <div>
              <ul class="Tonglist">
                <li
                  v-for="(item, key) in newzclsit"
                  :key="key"
                  v-show="key < 6 && item.status === 1"
                >
                  <span>{{ item.createTime | formatDate }}</span>
                  <a
                    @click="requestNewPolicy(item.id)"
                    style="cursor: pointer"
                    >{{ item.title }}</a
                  >
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
        <el-col :span="9" class="homescnew">
          <el-card class="box-card cardboder">
            <div slot="header" class="clearfix header">
              <span>快捷申报</span>
              <router-link to="/declareitem" class="moes">更多></router-link>
            </div>
            <div>
              <ul class="Bonglist">
                <li
                  v-for="(item, key) in declarelist"
                  :key="key"
                  v-show="key < 7"
                >
                  <el-row>
                    <el-col :span="24">
                      <a
                        @click="requestDeclare(item.id)"
                        style="cursor: pointer"
                        >{{ item.title }}</a
                      >
                    </el-col>
                    <!-- <el-col :span="4" :offset="2">
                      <el-tag
                        v-if="isShow(item) > 0"
                        type="success"
                        size="mini"
                      >
                        正在申报
                      </el-tag>
                      <el-tag v-else type="warning" size="mini"
                        >申请结束--</el-tag
                      >
                    </el-col> -->
                  </el-row>
                  <el-divider content-position="right" class="fegex">
                  </el-divider>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="hr-20"></div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="480px"
      :showClose="showClo"
      style="margin-top: 4%"
    >
      <span>您还未认证，请立即前往认证！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="requestGoAuthentication"
          >去认证 >></el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Moment from "moment";
export default {
  data() {
    return {
      leix: sessionStorage.getItem("Leix") || "",
      activeName: "second",
      activeName1: "quick",
      dialogVisible: false,
      authenticationRole: sessionStorage.getItem("Leix"),
      authenticationId: this.$route.query.userId,
      showClo: false,
      Column: "",
      Noticelsit: [],
      newzclsit: [],
      declarelist: [],
      Announcement: "",
      Declared: "",
    };
  },
  //用于数据初始化啊啊
  created() {
    this.GetColumn(); //获取所有栏目
    this.Getdeclarelist();
    this.requestAuthentication();
  },
  //用于存放所有的事件方法集合
  methods: {
    isShow(item) {
      let enddays = Moment(item.expireTime).diff(Moment(), "minute");
      console.log(item.expireTime, enddays / 24 / 60);
      return enddays / 24 / 60;
    },
    requestAuthentication() {
      if (this.authenticationRole === "talent") {
        this.$axios
          .get("/talent?id=" + this.authenticationId)
          .then((response) => {
            if (response.data.data.status === (0 || 1)) {
              this.dialogVisible = true;
            }
          });
      } else if (this.authenticationRole === "enterprise") {
        this.$axios
          .get("/enterprise?id=" + this.authenticationId)
          .then((response) => {
            if (response.data.data.status === (0 || 1)) {
              this.dialogVisible = true;
            }
          });
      }
    },

    requestGoAuthentication() {
      this.dialogVisible = false;
      if (this.authenticationRole === "talent") {
        this.$router.push({ path: "/probate" });
      } else if (this.authenticationRole === "enterprise") {
        this.$router.push({ path: "/qyprocessing" });
      }
    },
    GetColumn() {
      var self = this;
      this.$axios
        .get("/column/page", {
          params: {
            current: 1,
            size: 20,
          },
        })
        .then(function(res) {
          self.Column = res.data.data.records;
          for (const key in self.Column) {
            self.Getlumnlist(self.Column[key]);
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    requestNewPolicy(id) {
      let newPage = this.$router.resolve({
        name: "detail",
        params: { id },
      });
      window.open(newPage.href, "_blank");
    },

    requestNotice(id) {
      let newPage = this.$router.resolve({
        name: "details",
        params: { id },
      });
      window.open(newPage.href, "_blank");
    },

    requestDeclare(id) {
      let newPage = this.$router.resolve({
        name: "declarewz",
        params: { id },
      });
      window.open(newPage.href, "_blank");
    },

    Getlumnlist(column) {
      var self = this;
      this.$axios
        .get("/article/column", {
          params: {
            columnId: column.id,
            size: 10,
            status: 1,
            current: 1,
          },
        })
        .then(function(res) {
          if (column.name === "通知公告") {
            console.log(res.data.data.records);
            if (res.data.data.records)
              res.data.data.records.forEach(function(item) {
                if (item.status === 1) {
                  self.Noticelsit.push(item);
                }
              });
          } else if (column.name === "最新政策") {
            if (res.data.data.records)
              res.data.data.records.forEach(function(item) {
                if (item.status === 1) {
                  self.newzclsit.push(item);
                }
              });
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    Getdeclarelist() {
      var self = this;
      this.$axios
        .get("/policy/page", {
          params: {
            current: 1,
            size: 20,
            status: 1,
          },
        })
        .then(function(res) {
          self.declarelist = res.data.data.records;
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    islogin() {
      this.$message.error("请先登录账号！");
    },
  },
};
</script>

<style lang="less" scoped>
.tzhggaomoes {
  color: #fff;
  position: absolute;
  left: 24px;
  top: 132px;
  text-decoration: none;
  font-size: 16px;
}

.cardboder {
  border-top: #2380d7 5px solid;
  position: relative;

  .header {
    text-align: left;

    span {
      color: #2380d7;
      font-weight: bold;
    }
  }

  .moes {
    position: absolute;
    top: 21px;
    right: 24px;
    color: #2380d7;
    font-size: 14px;
    text-decoration: none;
    z-index: 8;
  }

  .Tonglist {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      height: 40px;
      background: url("../../assets/diann.jpg") no-repeat left center;

      a {
        font-size: 16px;
        text-decoration: none;
        color: #333;
        line-height: 40px;
        display: block;
        float: left;
        padding: 0 0 0 14px;
      }

      a:hover {
        color: #c00;
      }

      span {
        font-size: 16px;
        color: #888;
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }

  .Bonglist {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      height: 59px;

      .fegex {
        margin: 13px 0;
      }

      a {
        background: url("../../assets/wenbtub.jpg") no-repeat left center;
        display: block;
        text-align: left;
        text-decoration: none;
        font-size: 15px;
        color: #333;
        padding-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  line-height: 150px;
  margin: 0;
}

.tzhggao {
  background: #2198fe url("../../assets/cyjd.jpg") no-repeat left center;
  height: 160px;
  padding-bottom: 16px;

  .slide {
    height: 120px;
    overflow: auto;
  }

  ul {
    padding: 0 0 0 48px;
    margin: 0;
    list-style: none;

    li {
      list-style: none;
      height: 40px;
      background: url("../../assets/diann.jpg") no-repeat left center;

      a {
        text-align: left;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        line-height: 40px;
        display: block;
        float: left;
        padding: 0 0 0 14px;
        width: 545px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a:hover {
        color: #c00;
      }

      span {
        font-size: 16px;
        color: #fff;
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }
}
@import "../../styles/common_responsive.less";
</style>
